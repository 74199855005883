import React from 'react'
import Classnames from "classnames"
import { scaleLinear } from 'd3-scale'
import { extent } from "d3-array"
import Transition from 'join-transition'

import LineChart from './LineChart'
import BarChart from './BarChart'
import Tooltip from '../Tooltip'
import styleVars from '../../styling/global_variables.scss'
import {
	dateShortFormat, dateAbbrevDay, dateAbbrevYear, dateTimeFormat, dollarFormat, pctFormat,
	pctWithSignFormat, volShortFormat, twoDecimalFormat
} from '../../constants'
import { TICKER, ISINDEX, ISCONSTANT, ISUS, NAME, CLOSE, VOL, CURRENCY } from '../../constants'
import { checkForValue } from '../../helpers'

const svgHeight = 300
const chartMargins = { top: 10, bottom: 20, left: 50, right: 10 }
const defaultLineYAxisKey = "close"
const dateKey = "date"

const formatSeries = (data) => {
	// if there is no data (because either there is no selected component, or the
	// index is hidden, just return null, which we filter out when we build the lines array
	if (!data) return null
	// brings in properties for hover action
	const [ series ] = data

	return series.history.map(e => ({
		...e,
		isIndex: series[ISINDEX],
		isConstant: series[ISCONSTANT],
		ticker: series[TICKER],
		name: series[NAME],
		date: new Date(e.date)
	}))
}

const determineQuad = (x, y, width, height) => {
	if (x > width/2 && y > height/2) return 'q3'
	else if (x >= width/2 && y <= height/2) return 'q2'
	else if (x < width/2 && y > height/2) return 'q4'
	else return 'q1'
}

const determineDateQuad = (x, lineY, chartWidth, chartHeight, height) => {
	if (x > chartWidth/2 && lineY <= chartHeight - height) return 'q3'
	else if (x >= chartWidth/2 && lineY > chartHeight - height) return 'q2'
	else if (x < chartWidth/2 && lineY <= chartHeight - height) return 'q4'
	else return 'q1'
}

const getTooltips = (xScale, dateSet, dataSelectionKey, lineYScale, barYScale, chartWidth, lineChartHeight, barChartHeight, hoverData, timespan) => {
	const x = xScale(dateSet.indexOf(hoverData.data.date && hoverData.data.date.toString()))
	const lineY = lineYScale(hoverData.data[dataSelectionKey])
	const barY = barYScale(hoverData.data[VOL])
	const heightToBarChart = lineChartHeight + chartMargins.top + chartMargins.bottom
	const { data } = hoverData
	const tooltips = [
		{ key: 'nameTooltip',
				isIndex: data[ISINDEX],
				width: 55,
				contentArray: [{ text: data[TICKER] }],
				x, y: 0, quad: x < chartWidth / 2 ? 'q4' : 'q3'},
			{ key: 'lineTooltip',
				isIndex: data[ISINDEX],
				width: 90,
				contentArray: [{ text: `${twoDecimalFormat(data[CLOSE])} ${data[CURRENCY] || 'USD'}`}, // TODO: pass in currency at top level
					{ text: pctWithSignFormat(data["_percentChange"]) }],
				x, y: lineY, quad: determineQuad(x, lineY, chartWidth, lineChartHeight) },
			{ key: 'dateTooltip',
				isIndex: data[ISINDEX],
				width: timespan === '5D' || timespan === '1D' ? 100 : 75,
				contentArray:[{ text: timespan === '5D' || timespan === '1D' ? dateTimeFormat(data.date) : dateShortFormat(data.date) }],
				x, y: lineChartHeight, quad: determineDateQuad(x, lineY, chartWidth, lineChartHeight, 30) },
			{ key: 'barTooltip',
				opacity: +(!data[ISINDEX]), width: 90,
				contentArray:[{ text: volShortFormat(data[VOL]) }],
				x, y: heightToBarChart + barY, quad: determineQuad(x, barY, chartWidth, barChartHeight) }
			].filter(d => checkForValue(d.y) && checkForValue(d.x))
	const accessories = { x, lineHeight: !hoverData.data[ISINDEX] ? heightToBarChart + barY : lineChartHeight, circlePos: checkForValue(lineY) ? lineY : lineChartHeight, color: data[ISINDEX] ? styleVars.accent : styleVars.main }
	return {tooltips, accessories}
}

class Ticker extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			svgWidth: 880,
			hoverVisible: false,
		}
		this.onResize = this.onResize.bind(this)
		this.setHover = this.setHover.bind(this)
	}

	componentDidMount() {
		window.addEventListener('resize', () => {
			this.onResize()
		})
		this.onResize()
	}

	componentWillUnmount() {
		window.removeEventListener('resize') // prevent memory leaks
	}

	onResize() {
		const tickerWidth = this.refs.ticker.getBoundingClientRect().width
		this.setState({
			svgWidth: Math.min(880, tickerWidth)
		})
	}

	setHover(d, source) {
		this.setState({
			hoverData: {
				source,
				...d
			}
		})
	}

	render() {
		const { index, selectedComponent, timespan, hideIndex, isMobile } = this.props
		const chartWidth = this.state.svgWidth - chartMargins.left - chartMargins.right

		const lineConfig = {
			componentHeight: selectedComponent ? svgHeight*.7 : svgHeight,
			componentWidth: this.state.svgWidth,
			chartWidth: chartWidth,
			dataSelectionKey: !!selectedComponent && !hideIndex ? "_percentChange" : defaultLineYAxisKey,
			yAxisFormat: !!selectedComponent && !hideIndex ? pctFormat : dollarFormat
		}

		lineConfig.chartHeight = lineConfig.componentHeight - chartMargins.top - chartMargins.bottom

		const barConfig = {
			componentHeight: svgHeight*.3,
			componentWidth: this.state.svgWidth,
			chartWidth: chartWidth,
			yOffset: lineConfig.componentHeight,
			dataSelectionKey: "volume",
			yAxisFormat: volShortFormat,
		}

		barConfig.chartHeight = barConfig.componentHeight - chartMargins.top - chartMargins.bottom

		const indexData = formatSeries(index).filter(d => checkForValue(d[defaultLineYAxisKey]))
		const componentData = selectedComponent ? formatSeries(selectedComponent).filter(d => checkForValue(d[defaultLineYAxisKey])) : null
		const lines = !hideIndex
			? [indexData, componentData].filter(d => d !== null && d.length !== 0)
			: [componentData]

		const data = lines
			.map(d => d
				.map(e => ({
						...e,
						"_percentChange": (e[defaultLineYAxisKey] - d[0][defaultLineYAxisKey]) / d[0][defaultLineYAxisKey]
					})))

		// this is used to determine the entire extent of dates included in the data passed in -- in case one line
		// has more date values than the other
		const flatLineData = [].concat(...(data))
		const flatLineDataComponent = flatLineData.filter(e => !e[ISINDEX])
		// this then returns all dates in a set to remove duplicates and find unique dates
		const dateSet = [...new Set(flatLineData.map(d => d[dateKey]).sort((a,b) => a - b).map(d => d.toString()))]
		let tickCount =  isMobile ? 5 : 8
		let dateFormat
			if (timespan === '5D' || timespan === '1D') {
				dateFormat = dateTimeFormat
				tickCount = isMobile ? 4 : tickCount
			} else if (timespan === 'MAX') {
				dateFormat = dateAbbrevYear
			} else {
				dateFormat = dateAbbrevDay
			}


		const formattedDateSet = dateSet.map(e => dateFormat(new Date(e)))

		// the fewest amount of data points will be 1 month (20) since one day will be 27.
		// if the user checks in the morning and there are only a few data points for that day,
		// it should create a longer axis implying an entire day will take place
		const xScale = scaleLinear()
			.domain([0, Math.max(20, dateSet.length - 1)])
			.rangeRound([0, chartWidth])

		const lineYScale = scaleLinear()
			.domain(extent(flatLineData, d => d[lineConfig.dataSelectionKey]))
			.range([lineConfig.chartHeight, 0])
			.nice()

		const barYScale = scaleLinear()
			.domain(extent(flatLineDataComponent, d => d[barConfig.dataSelectionKey]))
			.range([barConfig.chartHeight, barConfig.chartHeight/6])
			.nice()

		// By leveraging "mapIndex", we can be sure that the data is plotted at the appropriate scaled index as
		// per the larger dateSet (which is controlling the axis) rather than the index of the componponet
		const mapIndex = (date) => dateSet.indexOf(date.toString())

		const tooltipDetails = this.state.hoverData
			? getTooltips(xScale, dateSet, lineConfig.dataSelectionKey, lineYScale, barYScale, chartWidth, lineConfig.chartHeight, barConfig.chartHeight, this.state.hoverData, timespan)
			: { tooltips: [], accessories: null }

		const xScaleDomain = xScale.domain()

		return (
			<div className="Ticker" ref="ticker">
				<Transition
					values={xScaleDomain[1]}
					duration={1000}
				>{nextValue => {
					// xScale.domain([xDomainMin, xDomainMax])
					xScale.domain([xScaleDomain[0], nextValue])
					return (<svg
						style={{ overflow: 'visible' }} // TO DO: add this to styles or make it dynamic with tooltips
						width={this.state.svgWidth}
						height={svgHeight}
						>
						<defs>
					    <filter id="lineShadow">
					    	<feDropShadow dx="1" dy="-1" stdDeviation="2 2" floodOpacity=".3"/>
					    </filter>
					  </defs>
						<LineChart
							dataSelection={data}
							dataSelectionKey={lineConfig.dataSelectionKey}
							dateKey={dateKey}
							dateSet={dateSet}
							flatLineData={flatLineData}
							formattedDateSet={formattedDateSet}
							margin={chartMargins}
							chartWidth={lineConfig.chartWidth}
							chartHeight={lineConfig.chartHeight}
							mapIndex={mapIndex}
							xScale={xScale}
							yScale={lineYScale}
							yAxisFormat={lineConfig.yAxisFormat}
							uniqueKey={"ticker"}
							onHover={(d) => this.setHover(d, 'lineChart')}
							onMouseEnter={() => this.setState({ hoverVisible: true })}
							onMouseLeave={() => this.setState({ hoverVisible: false })}
							tickCount={tickCount}
						/>
						{selectedComponent && (selectedComponent[0][ISUS] || !(timespan === '5D' || timespan === '1D'))
							? <BarChart
									data={flatLineDataComponent}
									translate={[0, barConfig.yOffset]}
									formattedDateSet={formattedDateSet}
									dateSet={dateSet}
									chartWidth={barConfig.chartWidth}
									chartHeight={barConfig.chartHeight}
									margin={chartMargins}
									dateKey={dateKey}
									yAxisKey={barConfig.dataSelectionKey}
									yAxisFormat={barConfig.yAxisFormat}
									mapIndex={mapIndex}
									xScale={xScale}
									yScale={barYScale}
									onHover={(d) => this.setHover(d, 'barChart')}
									onMouseEnter={() => this.setState({ hoverVisible: true })}
									onMouseLeave={() => this.setState({ hoverVisible: false })}
									tickCount={tickCount}
								/> : null }
							<g className={Classnames('tooltip-wrapper', {'visible': this.state.hoverVisible})}>
								{tooltipDetails.tooltips
									.filter(e => e) // remove nulls from lack of data
									.map(d =>
										<g key={d.key}
											className={Classnames('tooltipGroup', { 'index': d[ISINDEX] })}
											style={{ opacity: d.opacity, transform:`translate(${chartMargins.left + d.x}px, ${chartMargins.top + d.y}px)` }}>
											<Tooltip quad={d.quad} width={d.width} contentArray={d.contentArray} />
										</g>
									)
								}
								{tooltipDetails.accessories
									? <g className='tooltipAccessory'
										style={{ transform: `translate(${chartMargins.left + tooltipDetails.accessories.x}px)` }}>
										<path
											className="vertLine"
											d={`M 0 ${chartMargins.top} L 0 ${chartMargins.top + tooltipDetails.accessories.lineHeight}`}
										/>
										<circle
											className="circle"
											cx={0} cy={0} r={3}
											style={{ fill: tooltipDetails.accessories.color, transform: `translate(0px,${chartMargins.top + tooltipDetails.accessories.circlePos}px)` }}
										/>
									</g>
									: null}
							</g>
					</svg>)
				}}</Transition>
			</div>
		)
	}
}

export default Ticker