import React from "react"
import { connect } from "react-redux"
import { setHover, setSelected } from "../../actions.js"
import { scaleLinear, scaleSequential } from "d3-scale"
import { extent } from "d3-array"
import { interpolateRdYlGn } from "d3-scale-chromatic"
import { hierarchy, treemap } from "d3-hierarchy"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import Classnames from "classnames"
import Tooltip from '../Tooltip'
import { pctFormat, pctRoundFormat, volLongFormat, volShortFormat, dollarFormat,
	DISPLAY_NAME_LOOKUP, HEATMAP, VOL, NAME, METRIC, METRIC_NAME, DOWN,
	HEATMAP_MAX_W, HEATMAP_MIN_W, MAX_COMP_NUM } from '../../constants'
import { checkForValue, truncate } from '../../helpers'

import Axis from '../Axis'
import styleVars from '../../styling/global_variables.scss'
import "./style.scss";

/* tooltip vars */
const defaultTooltip = {
				contentArray: [{text: ' '}],
				width: 100,
				padding: 10,
				x: 100,
				y: 100,
				quad: 'q1'
			}

const calcHeatmapPosition = (data, options) => {
	const { componentHeight, componentWidth, margin, sortKey, primary } = options
	// adjust component width/height for margins on either side
	const compWidthMarginAdj = componentWidth - (2 * margin)
	const compHeightMarginAdj = componentHeight - (2 * margin)

	// change the heatmap constants based on how many components available
	const adjustWidth = (val) => ((data.length <= MAX_COMP_NUM) ? val : val/2)

	const columns = Math.max(Math.ceil(compWidthMarginAdj / adjustWidth(HEATMAP_MIN_W)), Math.floor(compWidthMarginAdj / adjustWidth(HEATMAP_MAX_W)))
	const rows = Math.ceil(data.length / columns)
	const height = Math.ceil((compHeightMarginAdj / rows) - margin);
	const width = Math.ceil((compWidthMarginAdj / columns) - margin)

	// this allows us to get the correct left position based on the index of the sorted data
	// without mutating the original data into a sort position
	const sortedData = [...data].sort((a, b) => +a[sortKey] - +b[sortKey])
	let sortedIndex = new Map()
	sortedData.forEach((d,i) => sortedIndex.set(d[primary], i))

	return data.map((d, i) => {
		const top = (Math.floor(sortedIndex.get(d[primary]) / columns)) * (height + margin)
		const left = margin + (sortedIndex.get(d[primary]) % columns) * (width + margin)

		return { ...d, position: { height, width, top, left } }
	})
}

const calcBarPosition = (data, options) => {
	const { sizeKey, sortKey, componentHeight, componentWidth, margin, yScale, primary } = options
	const width = ((componentWidth - 2 * margin) / data.length) - margin

	// this allows us to get the correct left position based on the index of the sorted data
	// without mutating the original data into a sort position
	const sortedData = [...data].sort((a, b) => +a[sortKey] - +b[sortKey])
	let sortedIndex = new Map()
	sortedData.forEach((d,i) => sortedIndex.set(d[primary], i))

	return data.map((d, i) => {
		const height = componentHeight - yScale(d[sizeKey])
		const top = yScale(d[sizeKey])
		const left = margin + sortedIndex.get(d[primary]) * (width + margin)

		return { ...d, position: { height, width, top, left } }
	})
}

const calcTreemapPosition = (data, options) => {
	const { componentWidth, componentHeight, sizeKey, margin, primary } = options

	let originalIndex = new Map()
	data.forEach((d,i) => originalIndex.set(d[primary], i))

	return treemap()
    .size([componentWidth, componentHeight])
    .padding(margin)
    .round(true)(
    	hierarchy({
			  	"children": data
			  }).sum(d => d[sizeKey])
			  	.sort((a, b) => b['value'] - a['value']))
		.leaves()
  	.map(d => ({
  		...d.data,
  		position: {
  			top: d.y0,
  			left: d.x0,
  			height: d.y1 - d.y0,
  			width: d.x1 - d.x0
  		}
  	}))
  	.sort((a,b) => originalIndex.get(a[primary]) - originalIndex.get(b[primary]) )
}


const ThreeInOneLabel = ({ view, primary, secondary, width, height }) => (
	<div className={Classnames('cardLabelParent', view,
		{hide: (view === 'bar' && width < 40) || (view === 'treemap' && width < 60) || (height < 20)}
	)}>
		<div className={Classnames('cardLabel', view)}> {primary} </div>
		{view !== 'bar' ? <div className={Classnames('cardSubLabel', view, {hide: height < 45})}> {truncate(secondary, 35)} </div> : null}
	</div>
)

class ThreeInOne extends React.Component {

	constructor(props) {
		super(props)
		this.getTooltip = this.getTooltip.bind(this)
	}

	getTooltip(data){
		const { view, colorKey, componentHeight, componentWidth, sizeKey }  = this.props

		const formatMapping = {
			'change_p': pctFormat,
			'weight' : pctFormat,
			'close' : dollarFormat,
			'volume' : volLongFormat
		}

		const heightCheck = componentHeight / 2
		const widthCheck = componentWidth / 2
		const pos = data.position

		const determineQuad = () => {
			if (pos.left <= widthCheck && pos.top < heightCheck) return 'q1'
			else if (pos.left > widthCheck && pos.top >= heightCheck) return 'q3'
			else if (pos.left >= widthCheck && pos.top < heightCheck) return 'q2'
			else if (pos.left < widthCheck && pos.top >= heightCheck) return 'q4'
			else return 'q1'
		}

		const quad = determineQuad()

		let contentArray = [ {text: data.ticker, class: NAME, lineOffset: 0},
			{ text: checkForValue(data[colorKey]) ? formatMapping[colorKey](data[colorKey]) : '--', class: METRIC, lineOffset: 0 },
			{ text: DISPLAY_NAME_LOOKUP[colorKey], class: METRIC_NAME, lineOffset: 1}]

		/* Bar chart additions*/
		let barChartAdditions = {}
		if (view !== HEATMAP && !(colorKey === VOL && sizeKey === VOL)){ // case where colorKey and sizeKey are both `Volume`
					barChartAdditions.contentArray = [ ...contentArray,
						{ text: formatMapping[sizeKey](data[sizeKey]), class: METRIC, extraLineHeight: true, lineOffset: 2 },
						{ text: sizeKey, class: METRIC_NAME, lineOffset: 3}]
		}

		return {...defaultTooltip,
				...{quad,
						x: ['q2', 'q3'].includes(quad) ? pos.left : pos.left + pos.width,
						y: pos.top,
						contentArray},
				...barChartAdditions
		}
	}

	render() {
		const { data, componentHeight, componentWidth, view, sizeKey, colorKey, colorScale, sortKey,
			label, lookup, primary, secondary, onHover, onClick, hover, selected, scrolledDown, introDone, isMobile } = this.props

		const _colorScale = colorScale || scaleSequential(interpolateRdYlGn)
			.domain(extent(data.map(d => +d[colorKey])))

		const yScale = scaleLinear()
			.domain(extent(data.map(d => +d[sizeKey])))
			.range([componentHeight, 0])
			.nice()

		const introDelay = introDone ? 0 : +styleVars.introDuration.slice(0, -2)
		const cardDelay = introDone ? 0 : (+styleVars.cardDuration.slice(0, -2) / data.length)
		const margin = this.props.margin || 2
		const options = {
			componentHeight,
			componentWidth,
			margin,
			sizeKey,
			sortKey,
			yScale,
			primary
		}

		let positionedData = []
		if (view === 'heatmap') {
			positionedData = calcHeatmapPosition(data, options)
		} else if (view === 'treemap') {
			positionedData = calcTreemapPosition(data, options)
		} else {
			positionedData = calcBarPosition(data, options)
		}

		const yScaleFormatMapping = {
			'weight' : pctRoundFormat,
			'close' : dollarFormat,
			'volume': volShortFormat
		}

		let hoveredData = positionedData.find(d => d.ticker === hover)
		let tooltip = hoveredData ? this.getTooltip(hoveredData) : defaultTooltip

		return (
			<div className={Classnames("ThreeInOne", {'disablePointer': !scrolledDown })}
				style={{width: componentWidth }}>
				<svg className={Classnames("axis-wrapper",{'visible': view === 'bar'})}
					width={componentWidth}
					height={componentHeight}>
					<Axis
						orient={'Right'}
						scale={yScale}
						format={yScaleFormatMapping[sizeKey]}
						ticks={5}
						tickSize={componentWidth}
						dx={4}
						dy={-4}
					/>
				</svg>
				<TransitionGroup component="div" className="transition-group">
					{positionedData.map((d,i) =>
						<CSSTransition
							in={true}
							key={d[primary]}
							timeout={introDelay + (cardDelay*positionedData.length)}
							appear={true}
							classNames="fade"
						>
							<div
								className={Classnames("card", { 'highlight': selected ? (selected === d[lookup]) : (hover === d[lookup]), scrolledDown, 'colorError': !checkForValue(d[colorKey])})}
								style={{
									...d.position,
									backgroundColor: scrolledDown ? _colorScale(d[colorKey]) : styleVars.white,
									transitionDelay: `${introDelay + (i*cardDelay)}ms`
								}}
								onClick={() => {
									onClick(d[lookup])
									onHover(null)
								}}
								onMouseEnter={() => isMobile ? null : onHover(d[lookup])}
								onMouseLeave={() => isMobile ? null : onHover(null)}
							>
								{label ? <ThreeInOneLabel
										view={view}
										primary={d[primary]}
										secondary={d[secondary].toLowerCase()}
										width={d.position.width}
										height={d.position.height}
									/> : null }
							</div>
						</CSSTransition>)}
					</TransitionGroup>
					<svg className={Classnames('tooltip-wrapper', {'hasData': hover})}
						style={{transform:`translate(${tooltip.x}px, ${tooltip.y}px)`,
										width: tooltip.width + tooltip.padding,
										overflow: 'visible'}}>
						 <Tooltip
								quad={tooltip.quad}
								width={tooltip.width}
								contentArray={tooltip.contentArray}
								/>
					</svg>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	scrolledDown: state.interaction.scrollSection === DOWN,
	introDone: state.interaction.introDone,
	hover: state.interaction.hover,
	selected: state.interaction.selected,
	timespan: state.interaction.timespan,
	isMobile: state.interaction.isMobile
})
const mapDispatchToProps = (dispatch) => ({
	setHover: component => dispatch(setHover(component)),
	setSelected: component => dispatch(setSelected(component)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ThreeInOne);