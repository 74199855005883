import React from "react"
import Axis from '../../Axis'
import "../style.scss";
import { TICK_W } from '../../../constants'
import { checkForValue } from '../../../helpers'

class BarChart extends React.Component {

	_onHover(hoverOptions, event) {
		const { dateKey, dateSet, onHover } = this.props
		const { yAxisKey, data, xScale, yScale } = hoverOptions


		const { top, left } = event.target.getBoundingClientRect()
		const xPosValue = new Date(dateSet[Math.round(xScale.invert(event.clientX - left))])
		const yPosValue = yScale.invert(event.clientY - top)

		const closestData = data
			.filter(d => d[dateKey].getTime() === xPosValue.getTime())
			.reduce((t,v) =>  Math.abs(yPosValue - v[yAxisKey]) < Math.abs(yPosValue - t[yAxisKey]) ? v : t, { [yAxisKey] : Infinity })

		if (!checkForValue(closestData[dateKey])) return;
		const mouseData = {
			data: closestData,
		}
		onHover(mouseData)
	}

	render() {
		const { data, chartWidth, chartHeight, margin, formattedDateSet,
			 xScale, yScale, dateKey, mapIndex,
			yAxisKey, yAxisFormat, translate,
			onMouseEnter, onMouseLeave, tickCount } = this.props

		const hoverOptions = {
			yAxisKey,
			data,
			xScale,
			yScale
		}

		return(
			<g className="BarChart" transform={`translate(${translate.join(",")})`}>
				<g>
					<Axis // this is the axis that provides the grid layout and labels
						className='grid'
						orient={'Bottom'}
						scale={xScale}
						format={d => formattedDateSet[d]}
						ticks={tickCount}
						tickSize={chartHeight + TICK_W}
						translate={`translate(${margin.left}, ${margin.top})`}
						dy={TICK_W}
						domain={false}
						removeFirstandLast={true}/>
					<Axis
						className='bottom-line'
						orient={'Left'}
						scale={yScale}
						format={yAxisFormat}
						ticks={3}
						tickSize={chartWidth + TICK_W}
						translate={`translate(${chartWidth + margin.left}, ${margin.top})`}
						dx={-(chartWidth + TICK_W + 5)}
						domain={false}
						/>
					<Axis // this is just the ticks on the bottom
						className='bottom-ticks'
						orient={'Bottom'}
						scale={xScale}
						format={''}
						ticks={25}
						tickSize={TICK_W}
						translate={`translate(${margin.left}, ${chartHeight + margin.top})`}
						dy={TICK_W}
						domain={true}/>
				</g>
				{data.map((d,i)=> {
					const barWidth = Math.min((chartWidth / data.length)*0.5, 5)
					// this checks if it is the first bar in the chart OR the last bar in order to give it half a width.
					// it must equal the second item in the axis domain, since the final i value could just be in the middle of
					// the axis if the user is looking at intraday (the axis on intraday is the entire day)
					const modifiedBarWidth = (mapIndex(d[dateKey]) === xScale.domain()[0]) || (mapIndex(d[dateKey]) === xScale.domain()[1]) ? barWidth / 2 : barWidth
					const barXTranslate = i === 0 ? 0 : barWidth/2
					return <rect
						className={'bar'}
						key={i}
						width={modifiedBarWidth}
						height={chartHeight - yScale(d[yAxisKey])}
						transform={
							`translate(${
								margin.left + xScale(mapIndex(d[dateKey])) - barXTranslate
							},${
								margin.top + yScale(d[yAxisKey])
							})`
						}
					/>}
				)}
				<rect
					width={chartWidth}
					height={chartHeight}
					onMouseMove={this._onHover.bind(this, hoverOptions)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={this._onHover.bind(this, hoverOptions)}
					x={margin.left}
					y={margin.top}
					style={{fill: 'blue', opacity: 0, pointerEvents: 'fill'}}
				/>
			</g>
		)
	}
}

export default BarChart