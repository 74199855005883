import React from 'react'
import "./style.scss"
import Classnames from "classnames"
import { max } from "d3-array"
import { NAME } from '../../constants'

/**
* contentArray is designed to look like the following:
* 	[{ text: string,
* 			class: string (optional),
* 			extraLineHeight: true (optional),
* 			lineOffset: int (optional)
* 		},
* 		{...}]
*/

const Tooltip = ({ quad, text, contentArray }) => {
	const arrowLength = 7
	const letterWidth = 8 // Guessing conservative width
	const lineHeight = 13
	const padding = 7

	// reduces `contentArray` to an object where key= lineOffsest, and value=total string of characters on line
	const charactersPerLine = contentArray.reduce( (acc, d, i) => {
		const lineOffset = 'L' + (Number.isInteger(d.lineOffset) ? d.lineOffset : i) // cast to string to use as key
		acc[lineOffset] = acc[lineOffset] ? acc[lineOffset] + '   ' + d.text : d.text
		return acc
	}, {})

	const calcDimensions = () => {
		// height = [total # of lines * lineHeight] + [padding on top/bottom] + [additional space for headers]
		const numLines = Object.keys(charactersPerLine).length;
		const extraHeight = contentArray.filter(d => d.extraLineHeight).length * padding
		const height =  (lineHeight * numLines) + (2 * padding) + extraHeight

		// get largest string in tooltip
		const maxCharacters = max(Object.entries(charactersPerLine), d => d[1] ? d[1].length: 0)
		const width = (maxCharacters * letterWidth) + (2 * padding)

		return [width, height]
	}

	const [ width, height ] = calcDimensions()


	const calcTextY = (i, extraHeightCnt) => {
		// additional padding for the space before the second header
		let additionalPadding = (extraHeightCnt) * padding
		return padding + ((i + 1) * lineHeight) + additionalPadding
	}

	const arrowPath = { x0: 0, y0: 0, x1: 2*arrowLength, y1: arrowLength, x2: arrowLength, y2: 2*arrowLength }

	const position = {
		q1: {
			xOffset: arrowLength,
			yOffset: arrowLength,
			rotation: 0,
		},
		q2: {
			xOffset: -(width + arrowLength),
			yOffset: arrowLength,
			rotation: 90,
		},
		q3: {
			xOffset: -(width + arrowLength),
			yOffset: -(height + arrowLength),
			rotation: 180,
		},
		q4: {
			xOffset: arrowLength,
			yOffset: -(height + arrowLength),
			rotation: 270,
		}
	}

	let extraHeightCnt = 0;

	const supportsSVGDropShadow = window['SVGFEDropShadowElement'] !== undefined

	return (
		<g className="tooltip">
		  <defs>
		    <filter id="shadow">
		      <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.3"/>
		    </filter>
			</defs>
			<g style={{transform: `translate(${position[quad].xOffset}px,${position[quad].yOffset}px)`}}>
				<rect
					className="rect"
					width={`${width}px`}
					height={`${height}px`}
					filter={supportsSVGDropShadow ? 'url(#shadow)' : null}>
				</rect>
				{contentArray.map((d,i) => {
						if (d.extraLineHeight) ++extraHeightCnt // used to offset Y
						let lineOffset = Number.isInteger(d.lineOffset) ? d.lineOffset : i
						return <text className={Classnames("text", d.class)}
											key={i}
											x={ d.class === NAME ? padding : width - padding}
											y={ `${calcTextY(lineOffset, extraHeightCnt)}px` }
											dy="-.18em"
										>
											{d.text}
									</text>
					})}
			</g>
			<path
				className="arrow"
				style={{ transform:`rotate(${position[quad].rotation}deg)`}}
				d={`M${arrowPath.x0},${arrowPath.y0}L${arrowPath.x1},${arrowPath.y1}L${arrowPath.x2},${arrowPath.y2}`}>
			</path>
		</g>
	)
}

export default Tooltip;
