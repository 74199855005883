export const LOAD_HISTORICAL = "LOAD_HISTORICAL"
export function loadHistorical(data) {
  return { type: LOAD_HISTORICAL, payload: data }
}

export const LOAD_LIVE = "LOAD_LIVE"
export function loadLive(data) {
  return { type: LOAD_LIVE, payload: data }
}

export const SET_INTRO_STATUS = "SET_INTRO_STATUS"
export function setIntroStatus(introStatus) {
  return { type: SET_INTRO_STATUS, payload: introStatus }
}

export const SET_SCROLL_PERMISSIONS = "SET_SCROLL_PERMISSIONS"
export function setScrollPermissions(scrollPermissions) {
  return { type: SET_SCROLL_PERMISSIONS, payload: scrollPermissions }
}

export const SCROLL_SECTION = "SCROLL_SECTION"
export function setScrollSection(scrollSection) {
  return { type: SCROLL_SECTION, payload: scrollSection }
}

export const SET_TIMESPAN = "SET_TIMESPAN"
export function setTimespan(timespan) {
  return { type: SET_TIMESPAN, payload: timespan }
}

export const SET_HOVER = "SET_HOVER"
export function setHover(component) {
  return { type: SET_HOVER, payload: component }
}

export const SET_SELECTED = "SET_SELECTED"
export function setSelected(component) {
  return { type: SET_SELECTED, payload: component }
}

export const SET_VIEW = "SET_VIEW"
export function setView(view) {
  return { type: SET_VIEW, payload: view }
}

export const SET_COLORBY = "SET_COLORBY"
export function setColorBy(color) {
  return { type: SET_COLORBY, payload: color }
}

export const SET_SIZEBY = "SET_SIZEBY"
export function setSizeBy(size) {
  return { type: SET_SIZEBY, payload: size }
}

export const SET_ISMOBILE = "SET_ISMOBILE"
export function setIsMobile(isMobile) {
  return { type: SET_ISMOBILE, payload: isMobile }
}