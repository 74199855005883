import React from "react";
import * as Scroll from "react-scroll";
import { connect } from "react-redux";
import Classnames from "classnames";
import { timeout } from "d3-timer";
import {
  setScrollSection,
  setView,
  setIsMobile,
  setScrollPermissions,
} from "../actions.js";

import Landing from "../components/Landing";
import Video from "../components/Video";
import Viz from "../components/Viz";
import styleVars from "../styling/global_variables.scss";
import { DEFAULT, DOWN, UP, MOVINGDOWN } from "../constants";

import { ReactComponent as Corner } from "../assets/ornament.svg";

// initialize scroll stuff
let Events = Scroll.Events;
let scroll = Scroll.animateScroll;

const corners = ["topLeft", "topRight", "bottomLeft", "bottomRight"];
const calcHeaderHeight = isMobile =>
  isMobile
    ? +styleVars.mobileHeaderHeight.slice(0, -2)
    : +styleVars.headerHeight.slice(0, -2);

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // this range is set this way because the total app height isn't actually twice the window height, although it is two pages
      // it needs to consider the height of the header since the header cuts off the total view height
      scrollHeight:
        window.outerHeight - calcHeaderHeight(this.props.interaction.isMobile),
    };
    this.rootScroll = this.rootScroll.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  onResize() {
    if (this.props.scrolledDown) {
      // we have to explicitly call all three of these, because in case the scroll section is already DOWN, theapp won't update
      // if the user is in a weird middle state (since the app will already be DOWN and its not an update to props)
      this.props.setScrollPermissions(true);
      this.rootScroll(DOWN);
    } else {
      // otherwise just reset to the section i
      this.props.setScrollSection(this.props.interaction.scrollSection);
    }

    const isMobile =
      window.outerWidth <= +styleVars.minWidth.slice(0, -2) ||
      window.innerHeight <= +styleVars.minHeight.slice(0, -2);
    this.setState({
      scrollHeight: window.outerHeight - calcHeaderHeight(isMobile),
    });

    if (isMobile !== this.props.interaction.isMobile) {
      this.props.setIsMobile(isMobile);
      if (isMobile) {
        // re-adjust vh units for safair ios height bug
        let vh = window.innerHeight * 0.01;
        // Then set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    }
  }

  rootScroll(direction, destination = DEFAULT) {
    /*Type 1 of scroll trigger: default scrollHeight */
    // if scroll isn't allowed, don't do anything
    if (
      !this.props.interaction.scrollAllowed &&
      !this.props.interaction.isMobile
    )
      return;
    // wheel-scrolling on body can only happen during scrollEvent execution
    // caution: during this time the user can also wheel-scroll
    document.body.style.overflowY = "scroll";
    // otherwise, move to the right direction
    if (direction && (direction === DOWN || direction === MOVINGDOWN)) {
      scroll.scrollToBottom({
        duration: +styleVars.scrollDuration.slice(0, -2),
        ignoreCancelEvents: true,
        smooth: true,
      });
    } else if (direction && direction === UP) {
      scroll.scrollToTop({
        duration: +styleVars.scrollDuration.slice(0, -2),
        ignoreCancelEvents: true,
        smooth: true,
      });
    } else if (destination) {
      /*Type 2 of scroll trigger: scroll t line on selection (for mobile) */
      const destinationEl = document.getElementById(destination);
      destinationEl.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  componentDidMount() {
    // prevent wheel-scrolling on body until allowed by rootScroll
    document.body.style.overflowY = "hidden";

    Events.scrollEvent.register("end", (to, element) => {
      // after rootScroll, prevent wheel-scrolling on body again
      document.body.style.overflowY = "hidden";
      if (this.props.interaction.scrollSection === MOVINGDOWN) {
        this.props.setScrollSection(DOWN);
      }
    });

    window.addEventListener(
      "wheel",
      event => {
        // ignore wheel events on Components or Performance, which don't trigger rootScroll
        if (!this.props.interaction.scrollAllowed) return false;
        // check direction of scrollY, then setState which will trigger rootScroll in componentDidUpdate
        if (event.deltaY > 0) {
          this.props.setScrollSection(MOVINGDOWN);
        } else if (event.deltaY < 0) {
          this.props.setScrollSection(UP);
        }
      },
      false
    );

    window.addEventListener("resize", () => {
      this.onResize();
    });

    // if scrolledDownOrMoving, do nothing, otherwise trigger scroll down
    timeout(() => {
      if (!this.props.scrolledDownOrMoving) {
        this.props.setScrollSection(MOVINGDOWN);
      }
    }, +styleVars.scrollTimeout.slice(0, -2));

    this.onResize();
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.scrolledDownOrMoving;
    const current = this.props.scrolledDownOrMoving;
    // only trigger scroll if scrolledDownOrMoving has changed
    if (prev && !current) {
      // scroll up
      this.rootScroll(UP);
      this.props.setView("heatmap");
      // this moves the divs in the viz back to the top of their individual scroll so its not funky when its back down
      document.getElementById("components").scrollTop = 0;
      document.getElementById("performance").scrollTop = 0;
    } else if (current && !prev) {
      // scroll down
      this.rootScroll(MOVINGDOWN);
      this.props.setScrollPermissions(false);
    }
  }

  componentWillUnmount() {
    // prevent memory leaks
    Events.scrollEvent.remove("end");
    window.removeEventListener("resize");
    window.removeEventListener("wheel");
  }

  render() {
    return (
      <div className="App" id="App">
        <div
          className={Classnames("container", {
            overlayVisible: this.props.scrolledDownOrMoving,
          })}
        >
          <Landing
            scrollFunc={() =>
              this.props.setScrollSection(
                this.props.scrolledDownOrMoving ? UP : MOVINGDOWN
              )
            }
          />
          <Viz ref={this.props.threeInOneRef} scrollFunc={this.rootScroll} />
          <Video />
          {corners.map((d, i) => (
            <Corner
              key={`corner-${i}`}
              className={Classnames("curly", d, {
                overlayVisible: this.props.scrolledDownOrMoving,
              })}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
  scrolledDown: state.interaction.scrollSection === DOWN,
  scrolledDownOrMoving:
    state.interaction.scrollSection === DOWN ||
    state.interaction.scrollSection === MOVINGDOWN,
});
const mapDispatchToProps = dispatch => {
  return {
    setScrollSection: scrollSection =>
      dispatch(setScrollSection(scrollSection)),
    setScrollPermissions: scrollAllowed =>
      dispatch(setScrollPermissions(scrollAllowed)),
    setView: view => dispatch(setView(view)),
    setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);
