import React from 'react';
import ReactDOM from 'react-dom';
import { loadHistorical, setIsMobile } from "./actions"
import App from './containers/App';
import {json} from 'd3-fetch';
import './styling/main.scss'
import styleVars from './styling/global_variables.scss'

import { Provider } from "react-redux"
import { createStore } from 'redux';
import reducer from "./reducers"

const initialState = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const store = createStore(
 reducer,
 initialState
);

json(process.env.PUBLIC_URL+'/data/data.json')
	.then(function(historicalData) {
		store.dispatch(loadHistorical(historicalData))
		store.dispatch(setIsMobile(window.outerWidth <= +styleVars.minWidth.slice(0, -2)))

		ReactDOM.render(
			<Provider store={store}><App /></Provider>,
			document.getElementById('root'));
	})

