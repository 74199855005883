import React from 'react'
import { connect } from "react-redux"
import Classnames from "classnames"
import './style.scss'
import { DISPLAY_NAME_LOOKUP } from '../../constants'


class Button extends React.Component {

	render() {

		const niceValue = DISPLAY_NAME_LOOKUP[this.props.value] || this.props.value;

		return (
			<span
				className={Classnames('Button', this.props.category, {'disabled': this.props.disable, 'selected' : this.props.value === this.props.selectedValue})}
				onClick={() => this.props.onClick(this.props.value)}>
				{niceValue}
			</span>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	selectedValue: state.interaction[ownProps.storeLookup],
})

export default connect(mapStateToProps)(Button)