import React from "react"
import Classnames from "classnames"
import * as d3Axis from "d3-axis"
import { select } from "d3-selection"

import "./style.scss";

class Axis extends React.Component {

	componentDidMount() {
		this.drawAxis()
	}

	componentDidUpdate() {
		this.drawAxis()
	}

	drawAxis() {
    const axis = d3Axis[`axis${this.props.orient}`]()
			.scale(this.props.scale)
			.ticks(this.props.ticks)
			.tickSize(this.props.tickSize)
			.tickFormat(this.props.format)

		select(this.axisElement)
			.call(axis)

		if (!this.props.domain) {
			select(this.axisElement)
				.select('.domain').remove()
		}

		select(this.axisElement)
			.selectAll(".tick text").attr("x", this.props.dx).attr("dy", this.props.dy)
	}

	render() {
		return (
			<g
				className={Classnames('axis', `axis-${this.props.orient.toLowerCase()}`,
					{'removeFirstandLast': this.props.removeFirstandLast}, this.props.className)}
				ref={(el) => {this.axisElement = el}}
				transform={this.props.translate}
			/>
		)
	}
}

export default Axis