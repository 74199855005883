import React from 'react'
import { pctRoundFormat, roundToKFormat } from '../../constants'
import './style.scss'

class ColorScale extends React.Component {

	render() {
	const darkestPos = this.props.colorScale(this.props.colorScale.domain()[0])
	const darkestNeg = this.props.colorScale(this.props.colorScale.domain()[2])
	const formatMapping = {
		'change_p' : pctRoundFormat,
		'volume': roundToKFormat
	}
	const numFormat = formatMapping[this.props.colorBy]

		return (
			<div className="ColorScale"
				style={{
					minWidth: `${this.props.width}px`,
					height: `${this.props.height}px`,
					background: `linear-gradient(-90deg, ${darkestNeg}, ${this.props.colorScale.range()[1]}, ${darkestPos})`
				}}>
				<div className="colorTick left"> {numFormat(this.props.colorScale.domain()[0])} </div>
				<div className="colorTick center"> {numFormat(this.props.colorScale.domain()[1])} </div>
				<div className="colorTick right"> {numFormat(this.props.colorScale.domain()[2])} </div>
			</div>
		)
	}
}

export default ColorScale;