import React from "react";
import { connect } from "react-redux";
import {
  setTimespan,
  setHover,
  setSelected,
  setView,
  setColorBy,
  setSizeBy,
} from "../../actions.js";

import { scaleLinear } from "d3-scale";
import { extent, max } from "d3-array";
import Classnames from "classnames";

import Ticker from "../Ticker";
import ThreeInOne from "../ThreeInOne";
import HoldingsTable from "../HoldingsTable";
import Button from "../Button";
import ColorScale from "../ColorScale";
import StatsTable from "../StatsTable";
import styleVars from "../../styling/global_variables.scss";
import "./style.scss";
import {
  VIEW_OPTIONS,
  COLOR_OPTIONS,
  SIZE_OPTIONS,
  TIME_OPTIONS,
  CLOSE,
  HOLDING_TABLE,
  TICKER,
  THREEINONE,
  STATS,
  PROPERTIES_MAPPING,
  NAME,
  MS_1_DAY,
  timestampFormat,
  STATS_TABLE,
  DOWN,
  getTimezone,
} from "../../constants";
import { checkForValue } from "../../helpers";

import downloadImg from "../../assets/download.png";

// this helper function can be used to return the first object from an array of one
// which can be used for index array (since it will always have one)
const destructure = array => {
  const [data] = array;
  return data;
};

const calc3in1Pos = (top, left, scrolledDown, isMobile) => {
  if (!scrolledDown && !isMobile) {
    return { position: "fixed", top, left };
  } else {
    return { position: "relative" };
  }
};

const getRelevantProps = el => obj => {
  // pulls only characteristics necessary for element
  let properties = {
    [TICKER]: obj[TICKER],
    [NAME]: obj[NAME],
  };
  PROPERTIES_MAPPING[el].forEach(c => {
    properties[c] = obj[c];
  });
  return properties;
};

const findValue = history => {
  const filteredHistory = history.filter(d => checkForValue(d.close));
  if (filteredHistory.length === 0) return {};
  const startClose = filteredHistory[0].close;
  const lastClose = filteredHistory[filteredHistory.length - 1].close;
  return { startClose, lastClose };
};

const getFilteredHistory = (data, unit, filter = null) =>
  data.map(d => {
    const history = [
      ...d[unit]
        .filter(filter ? filter : e => true)
        .sort((a, b) => new Date(a.date) - new Date(b.date)),
      ...(unit === "daily" ? d.qtrhrly.slice(-1) : []),
    ];
    const isConstant = history.reduce(
      (t, v) => t && v[CLOSE] === history[0][CLOSE],
      true
    );
    const isUs = d.EXT === "US";
    const { startClose, lastClose } = findValue(history);
    const change = lastClose - startClose;
    const change_p = change / startClose;
    return {
      ...d,
      history,
      change,
      change_p: change_p,
      isConstant,
      isUs,
    };
  });

const filterData = (timespan, data) => {
  let unit =
    timespan === "YTD" || timespan === "MAX" ? timespan : timespan.slice(-1); // returns: 'D', 'M', 'Y', 'YTD', 'MAX'
  let filteredData;
  let minDate;
  let filterFunction;

  const maxTime = Math.max(
    ...new Set(...data.map(d => d.qtrhrly.map(e => new Date(e.date).getTime())))
  );

  switch (unit) {
    case "D": // should leverage quarter hourly only, and index is dependent on date
      if (timespan === "5D") {
        filteredData = getFilteredHistory(data, "qtrhrly");
      } else {
        const maxDate = new Date(Math.floor(maxTime / MS_1_DAY) * MS_1_DAY);
        filterFunction = e => new Date(e.date) >= maxDate;
        filteredData = getFilteredHistory(data, "qtrhrly", filterFunction);
      }
      break;
    case "M": // should leverage daily and pull the top value for qtrhrly
      const numMonths = +timespan.slice(0, 1); // '1' or '3'
      minDate = maxTime - numMonths * 30 * MS_1_DAY; // subtract 30/90 days
      filterFunction = e => new Date(e.date) >= minDate;
      filteredData = getFilteredHistory(data, "daily", filterFunction);
      break;
    case "Y": // should leverage daily and pull the top value for qtrhrly
      minDate = maxTime - 360 * MS_1_DAY; // subtract 360 days
      filterFunction = e => new Date(e.date) >= minDate;
      filteredData = getFilteredHistory(data, "daily", filterFunction);
      break;
    case "YTD": // should leverage daily and pull the top value for qtrhrly
      // e.date needs to be greater than or equal to jan 1st of the same year of the last data point
      const maxDate = Math.max(
        ...new Set(
          ...data.map(d => d.daily.map(e => new Date(e.date).getTime()))
        )
      );
      filterFunction = e =>
        new Date(e.date) >= new Date(new Date(maxDate).getFullYear(), 0);
      filteredData = getFilteredHistory(data, "daily", filterFunction);
      break;
    case "MAX": // should leverage daily and pull the top value for qtrhrly, but filter on max value
      filteredData = getFilteredHistory(data, "daily");
      break;
    default:
      console.log("Timespan unit not found");
      break;
  }
  return filteredData;
};

class Viz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentWidth: 860, // this is initialized here, but changed based on window size
      jumpingDivTop: null,
      jumpingDivLeft: null,
    };
    this.onResize = this.onResize.bind(this);
    this.deselectComponentFunction = this.deselectComponentFunction.bind(this);
    this.handleSelectionClick = this.handleSelectionClick.bind(this);
  }

  componentDidMount() {
    const performanceDiv = this.refs.performance;
    // check for resize activity and trigger function
    window.addEventListener("resize", () => {
      this.onResize(performanceDiv);
    });
    // but also just run this on mount
    this.onResize(performanceDiv);
  }

  componentWillUnmount() {
    // prevent memory leaks
    window.removeEventListener("resize");
  }

  handleSelectionClick(d) {
    const { scrollFunc, interaction, setSelected } = this.props;

    /* 1. set selections in store */
    // if its not the selected component, then select it
    if (interaction.selected === d) {
      this.deselectComponentFunction();
    } else setSelected(d);

    /* 2. set selections in store */
    if (interaction.isMobile) {
      scrollFunc(null, STATS_TABLE);
    }
  }

  deselectComponentFunction() {
    this.props.setSelected(null);
    if (this.state.hideIndex) {
      this.setState({ hideIndex: !this.state.hideIndex });
    }
  }

  onResize(performanceDiv) {
    const headerHeight = this.props.interaction.isMobile
      ? +styleVars.mobileHeaderHeight.slice(0, -2)
      : +styleVars.headerHeight.slice(0, -2);

    this.setState({
      componentWidth: Math.min(
        880,
        performanceDiv.getBoundingClientRect().width
      ),
      // TO DO: make this dynamic. This 130 is hard coded -- which includes the set margins and size of the divs on top.
      jumpingDivTop: headerHeight + 130,
      // this isn't affected by the entire height of the window, like the top value is
      jumpingDivLeft: performanceDiv.getBoundingClientRect().left,
      hideIndex: false,
    });
  }

  render() {
    const {
      data,
      interaction,
      scrolledDown,
      setTimespan,
      setHover,
      setView,
      setColorBy,
      setSizeBy,
    } = this.props;
    const {
      componentWidth,
      jumpingDivTop,
      jumpingDivLeft,
      hideIndex,
    } = this.state;
    const { view, isMobile, timespan, colorBy, sizeBy, selected } = interaction;

    const componentHeight = isMobile
      ? +styleVars.mobileThreeInOneHeight.slice(0, -2)
      : +styleVars.ThreeInOneHeight.slice(0, -2);
    const maxDate = max(data.index[0].qtrhrly, d => new Date(d.date));
    const lastUpdated = timestampFormat(maxDate) + " " + getTimezone(maxDate);

    const filteredData = filterData(timespan, data.securities);
    const filteredDataSelected = filteredData.filter(
      d => d.ticker === selected
    );
    const filteredDataIndex = filterData(timespan, data.index);
    const colorExtent = extent(
      filteredData.filter(d => checkForValue(d[colorBy])).map(d => d[colorBy])
    );
    const colorMax = Math.max(...colorExtent.map(Math.abs)) || 1;
    const customColorScale = scaleLinear()
      .domain([-colorMax, 0, colorMax])
      .range([styleVars.red, styleVars.white, styleVars.green]);

    const intradayDataCheck =
      filterData("1D", data.index)[0].history.length < 2;

    return (
      <div className="Viz">
        <div
          className="components"
          id="components"
          ref="components"
          style={{ overflowY: scrolledDown ? "scroll" : "hidden" }}
        >
          <div className="components titleDiv">
            <span className="title">Components</span>{" "}
            <span className="subtitle updated">{`As of ${lastUpdated}`}</span>
          </div>
          <HoldingsTable
            data={filteredData.map(getRelevantProps(HOLDING_TABLE))}
            onHover={d => setHover(selected ? null : d)}
            onClick={this.handleSelectionClick}
          />
          <div className="links">
            <a
              href={data.index[0].factsheetLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              FACTSHEET
              <img alt="download" src={downloadImg} />
              &nbsp;
            </a>
            {data.index[0].methodologyLink ? ( // check to see if have link
              <a
                href={data.index[0].methodologyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                METHODOLOGY
                <img alt="download" src={downloadImg} />
              </a>
            ) : null}
            <br />
            <br />
            <a
              href="https://www.primeindexes.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRIME INDEXES WEBSITE
            </a>
          </div>
        </div>

        <div
          className="performance"
          id="performance"
          ref="performance"
          height={componentHeight}
          style={{ overflowY: scrolledDown ? "scroll" : "hidden" }}
        >
          <div className="performance titleDiv" ref="performanceTitle">
            <span>
              <span className="title">Performance</span>
              <span className="subtitle"> (in $USD)</span>
            </span>
            <span className="subtitle updated">{`As of ${lastUpdated}`}</span>
          </div>
          <div className="selections">
            <div className="selection-group colorby">
              <span className="selection-label">color by:</span>
              {COLOR_OPTIONS.map(d => (
                <Button
                  key={d}
                  value={d}
                  onClick={d => setColorBy(d)}
                  category={"threeButtons"}
                  storeLookup={"colorBy"}
                />
              ))}
            </div>
            <div className="selection-group sizeby">
              {view !== "heatmap" ? (
                <span className="selection-label">size by:</span>
              ) : null}
              {view !== "heatmap"
                ? SIZE_OPTIONS.map(d => (
                    <Button
                      key={d}
                      value={d}
                      onClick={d => setSizeBy(d)}
                      category={"threeButtons"}
                      storeLookup={"sizeBy"}
                    />
                  ))
                : null}
            </div>
            <div className="selection-group viewas">
              <span className="selection-label">view as:</span>
              {VIEW_OPTIONS.map((d, i) => (
                <Button
                  key={d}
                  value={d}
                  onClick={d => setView(d)}
                  category={"threeButtons"}
                  storeLookup={"view"}
                />
              ))}
            </div>
          </div>
          {!scrolledDown ? (
            <div className="placeholder" style={{ position: "relative" }} />
          ) : null}
          <div className="performanceContent">
            <div
              className={Classnames("jumpingDiv", {
                disablePointer: !scrolledDown,
              })}
              style={calc3in1Pos(
                jumpingDivTop,
                jumpingDivLeft,
                scrolledDown,
                isMobile
              )}
            >
              <ThreeInOne
                data={filteredData.map(getRelevantProps(THREEINONE))}
                componentHeight={componentHeight}
                componentWidth={componentWidth}
                view={view}
                sizeKey={sizeBy}
                sortKey={!scrolledDown ? null : colorBy}
                colorKey={colorBy}
                colorScale={customColorScale}
                lookup={"ticker"}
                primary={"ticker"}
                secondary={"name"}
                label={true}
                onHover={setHover}
                onClick={this.handleSelectionClick}
              />
            </div>
            <div className="timespan-and-colors">
              <ColorScale
                width={componentWidth / 3}
                colorBy={colorBy}
                height={20}
                colorScale={customColorScale}
              />
              <span className="timespan">
                {TIME_OPTIONS.map(d => (
                  <Button
                    key={d}
                    value={d}
                    disable={d === "1D" && intradayDataCheck}
                    onClick={d => setTimespan(d)}
                    storeLookup={"timespan"}
                    category={"timeButtons"}
                  />
                ))}
              </span>
            </div>
            <StatsTable
              destructuredIndex={destructure(
                filteredDataIndex.map(getRelevantProps(STATS))
              )}
              destructuredSelectedComponent={
                interaction.selected
                  ? destructure(
                      filteredDataSelected.map(getRelevantProps(STATS))
                    )
                  : null
              }
              hideIndex={hideIndex}
              toggleIndex={() => this.setState({ hideIndex: !hideIndex })}
              deselectComponent={() => this.deselectComponentFunction()}
            />
            <Ticker
              hideIndex={hideIndex}
              index={filteredDataIndex.map(getRelevantProps(TICKER))}
              timespan={timespan}
              selectedComponent={
                interaction.selected
                  ? filteredDataSelected.map(getRelevantProps(TICKER))
                  : null
              }
              isMobile={interaction.isMobile}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => state => ({
  ...state,
  scrolledDown: state.interaction.scrollSection === DOWN,
});

const mapDispatchToProps = dispatch => {
  return {
    setTimespan: timespan => dispatch(setTimespan(timespan)),
    setHover: component => dispatch(setHover(component)),
    setSelected: component => dispatch(setSelected(component)),
    setView: view => dispatch(setView(view)),
    setColorBy: colorBy => dispatch(setColorBy(colorBy)),
    setSizeBy: sizeBy => dispatch(setSizeBy(sizeBy)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Viz);
