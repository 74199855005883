import React from 'react'
import { connect } from "react-redux"
import Classnames from "classnames"
import './style.scss'
import styleVars from '../../styling/global_variables.scss'

import video from '../../assets/video.mp4'
import { DOWN, MOVINGDOWN } from '../../constants'

class Video extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			videoWidth: null,
			videoHeight: null
		}
		this.onResize = this.onResize.bind(this)
	}

	componentDidMount() {
		window.addEventListener('resize', () => {
			this.onResize()
		})
		this.onResize()
	}

	componentWillUnmount() {
		window.removeEventListener('resize') // prevent memory leaks
	}

	onResize() {
		this.setState({
			videoWidth: Math.max(window.innerWidth, +styleVars.maxWidth.slice(0, -2) ),
			videoHeight: window.innerHeight

		})
	}

	render() {
		const supportsMixedBlendMode = 'mix-blend-mode' in document.body.style

		return (
			<div className="Video">
				<div className={Classnames("overlay", {"overlayVisible": this.props.scrolledDownOrMoving })}></div>
				{this.props.isMobile ?
					null :
					(<video className={Classnames("videoPlayer", {"overlayVisible": this.props.scrolledDownOrMoving })}
						controls={false} autoPlay={true} loop={false} muted={true}
						width={supportsMixedBlendMode ? this.state.videoWidth : null } height={this.state.videoHeight}
					>
						<source src={video} type="video/mp4" />
					</video>)
				}

			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.data.index,
	isMobile: state.interaction.isMobile,
	scrolledDownOrMoving: state.interaction.scrollSection === DOWN || state.interaction.scrollSection === MOVINGDOWN })

export default connect(mapStateToProps)(Video)