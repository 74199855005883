import React from "react"
import Classnames from "classnames"
import { connect } from "react-redux"
import { select } from "d3-selection"
import { line, area, curveMonotoneX } from "d3-shape"

import Axis from '../../Axis'
import "../style.scss";
import { TICK_W, ISINDEX, ISCONSTANT } from '../../../constants'
import { checkForValue } from '../../../helpers'

class LineChart extends React.Component {

	componentDidUpdate(prevProps){
		if (prevProps.timespan !== this.props.timespan) return; // let React transition handle timespan change
		const {uniqueKey} = this.props;

		let pathLength
		this.props.dataSelection
			.filter((d,i) =>
				!prevProps.dataSelection[i] ||
				d[0][uniqueKey] !== prevProps.dataSelection[i][0][uniqueKey]
			) // only transition a new line, i.e. "enter selection"
			.forEach((d,i) => {
				select(this.refs['path_'+d[0][uniqueKey]])
					.attr('stroke-dasharray', function(){
						return pathLength = this.getTotalLength();
					})
					.attr('stroke-dashoffset', pathLength)
					.transition()
						.duration(1000)
						.attr('stroke-dashoffset', 0)
						.on('end', function() {
							select(this).attr('stroke-dasharray', 0);
						})
			})
	}

	_onHover(hoverOptions, event) {
		const { dateKey, dateSet, onHover } = this.props
		const { dataSelectionKey, flatLineData, xScale, yScale } = hoverOptions

		const { top, left } = event.target.getBoundingClientRect()
		const xPosValue = new Date(dateSet[Math.round(xScale.invert(event.clientX - left))])
		const yPosValue = yScale.invert(event.clientY - top)

		const closestData = flatLineData
			.filter(d => d[dateKey].getTime() === xPosValue.getTime())
			.reduce((t,v) => Math.abs(yPosValue - v[dataSelectionKey]) < Math.abs(yPosValue - t[dataSelectionKey]) ? v : t, { [dataSelectionKey] : Infinity })

		if (!checkForValue(closestData[dataSelectionKey])) return
		const mouseData = {
			data: closestData,
		}
		onHover(mouseData)
	}

	render() {
		const { dataSelection, dataSelectionKey, dateKey, flatLineData, formattedDateSet,
			chartWidth, chartHeight, margin, xScale, yScale, yAxisFormat, uniqueKey, mapIndex,
			onMouseEnter, onMouseLeave, tickCount
		} = this.props

		const path = line()
			.x(d => xScale(mapIndex(d[dateKey])) + margin.left)
			.y(d => yScale(d[dataSelectionKey]) + margin.top)
			.curve(curveMonotoneX)

		const areaUnderCurve = area()
			.x(d => xScale(mapIndex(d[dateKey])) + margin.left)
			.y0(chartHeight + margin.top)
			.y1(d => yScale(d[dataSelectionKey]) + margin.top)
			.curve(curveMonotoneX)

		const hoverOptions = {
			dataSelectionKey,
			flatLineData,
			xScale,
			yScale
		}

		const supportsSVGDropShadow = window['SVGFEDropShadowElement'] !== undefined

		return (
			<g className="LineChart">
				<g>
					<Axis // this is the axis that provides the grid layout and labels
						className='grid'
						orient={'Bottom'}
						scale={xScale}
						format={d => formattedDateSet[d]}
						ticks={tickCount}
						tickSize={chartHeight + TICK_W}
						translate={`translate(${margin.left}, ${margin.top})`}
						dy={TICK_W}
						domain={false}
						removeFirstandLast={true}/>
					<Axis // this is the left side axis
						className='bottom-line'
						orient={'Left'}
						scale={yScale}
						format={yAxisFormat}
						ticks={8}
						tickSize={chartWidth + TICK_W}
						translate={`translate(${chartWidth + margin.left}, ${margin.top})`}
						dx={-(chartWidth + TICK_W + 5)}
						domain={false}
						/>
					<Axis // this is just the ticks on the bottom
						className='bottom-ticks'
						orient={'Bottom'}
						scale={xScale}
						format={''}
						ticks={25}
						tickSize={TICK_W}
						translate={`translate(${margin.left}, ${chartHeight + margin.top})`}
						dy={TICK_W}
						domain={true}/>
				</g>
				{dataSelectionKey === '_percentChange'
					? <line className='zero'
						x1={xScale.range()[0] + margin.left}
						x2={xScale.range()[1] + margin.left}
						y1={yScale(0) + margin.top}
						y2={yScale(0) + margin.top}
					/>
					: null
				}
				{dataSelection.map((d,i) =>
					<g key={`${d[0][uniqueKey]}_group`}>
						<path
							ref={"path_"+d[0][uniqueKey]}
							key={`${d[0][uniqueKey]}_tickerLine`}
							className={Classnames('tickerLine', d[0][uniqueKey], { index: d[0][ISINDEX], noDataChange: d[0][ISCONSTANT]})}
							d={path(d)}
							filter={supportsSVGDropShadow ? 'url(#lineShadow)' : null}
						/>
						<path
								className={Classnames('area', d[0][uniqueKey], {visible: dataSelectionKey !== '_percentChange'})}
								d={areaUnderCurve(d)}
							/>
					</g>
				)}
				<rect
					width={chartWidth}
					height={chartHeight}
					onMouseMove={this._onHover.bind(this, hoverOptions)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={this._onHover.bind(this, hoverOptions)}
					x={margin.left}
					y={margin.top}
					style={{fill: 'blue', opacity: 0, pointerEvents: 'fill'}}
					>
				</rect>
			</g>
		)
	}
}


const mapStateToProps = (state) => ({
	timespan: state.interaction.timespan,
})
export default connect(mapStateToProps)(LineChart)