import { combineReducers } from "redux"
import { UP } from  '../constants'
import * as ActionTypes from "../actions"

const initialState = {
	initialState: true,
	data: {
		index: 'initial state index',
		securities: []
	},
  interaction: {
    introDone: false,
    scrollSection: UP,
    scrollAllowed: true,
    timespan: '3M',
    hover: null,
    selected: null,
    view: 'heatmap',
    colorBy: 'change_p',
    sizeBy: 'weight',
    isMobile: null
  }
}

const data = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_HISTORICAL:
      return Object.assign({}, state, action.payload)

    case ActionTypes.LOAD_LIVE:
      return Object.assign({}, state, action.payload)

    default:
      return state
  }
}

const interaction = (state = initialState.interaction, action) => {
  switch (action.type) {

    case ActionTypes.SET_INTRO_STATUS:
      return Object.assign({}, state, { ...state.interaction, introDone: action.payload })

    case ActionTypes.SCROLL_SECTION:
      return Object.assign({}, state, { ...state.interaction, scrollSection: action.payload })

    case ActionTypes.SET_SCROLL_PERMISSIONS:
      return Object.assign({}, state, {...state.interaction, scrollAllowed: action.payload})

    case ActionTypes.SET_TIMESPAN:
      return Object.assign({}, state, { ...state.interaction, timespan: action.payload })

    case ActionTypes.SET_HOVER:
      return Object.assign({}, state, { ...state.interaction, hover: action.payload })

    case ActionTypes.SET_SELECTED:
      return Object.assign({}, state, { ...state.interaction, selected: action.payload })

    case ActionTypes.SET_VIEW:
      return Object.assign({}, state, { ...state.interaction, view: action.payload })

    case ActionTypes.SET_COLORBY:
      return Object.assign({}, state, { ...state.interaction, colorBy: action.payload })

    case ActionTypes.SET_SIZEBY:
      return Object.assign({}, state, {...state.interaction, sizeBy: action.payload})

    case ActionTypes.SET_ISMOBILE:
      return Object.assign({}, state, {...state.interaction, isMobile: action.payload})

    default:
      return state
  }
}


export default combineReducers({ data, interaction })