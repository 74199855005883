import React from 'react'
import Classnames from "classnames"
import "./style.scss"
import styleVars from '../../styling/global_variables.scss'
import {
	pctFormat, twoDecimalFormat, mktCapFormat,
	VOL, HIGH, LOW, MKTCAP, OPEN, CLOSE, STATS_TABLE,
} from '../../constants'
import { truncate, checkForValue } from '../../helpers'

import hideImg from '../../assets/hide.png'
import showImg from '../../assets/show.png'
import delImg from '../../assets/delete.png'

const topRow = [
	{ label: 'Open', key: OPEN, format: twoDecimalFormat },
	{ label: '52 High', key: HIGH , format: twoDecimalFormat },
	{ label: 'Volume', key: VOL, format: mktCapFormat }]

const bottomRow = [
	{ label: 'Prev. Close', key: CLOSE, format: twoDecimalFormat },
	{ label: '52 Low', key: LOW , format: twoDecimalFormat },
	{ label: 'Market Cap', key: MKTCAP, format: mktCapFormat }]

const rows = [topRow, bottomRow]
const valueReplacement = (data, format) => checkForValue(data) ? format(data) : ''

const Table = ({ data, imgName, imgFunction, tableClass, componentIsSelected }) => {
	const sign = data.change_p > 0 ? '+' : '';
	return (
		<div className={Classnames(`${tableClass}Stats`, { hide: imgName === hideImg })}>
			<div className={`${tableClass}Details`}>
				<div className={`${tableClass}DetailsRow`}>
					<div className="tickerImg">
						<span className={tableClass}>{data.ticker}</span>
						{componentIsSelected
							? <img className={Classnames({ hide: imgName === hideImg , del: imgName === delImg })} alt="show or hide icon" src={imgName} onClick={imgFunction}/> : null }
					</div>
					<span className="close">{valueReplacement(data.close, twoDecimalFormat)}</span>
				</div>
				<div className={`${tableClass}DetailsRow`}>
					<span className="statsName">{truncate(data.name, 25)}</span>
					<span className="change" style={{ color: `${data.change_p > 0 ? styleVars.green : styleVars.red}`}}>
						{`${sign}${valueReplacement(data.change, twoDecimalFormat)} (${sign}${valueReplacement(data.change_p, pctFormat)})`}</span>
				</div>
			</div>
			<div className="tableContainer">
			{tableClass === 'component'
				? <table className={`${tableClass}Table`}>
					<tbody>
						{rows.map((d,i) => {
							return <tr key={i} className={`${tableClass}Table`}>
								{d.map(e => {
								return <td key={e.label}
									className={Classnames('stats', {'top': i === 0 })}>
									<span className="statsRowLabel"> {e.label}: </span>
									<span className="statsRowData">
										{e.format ? valueReplacement(data[e.key], e.format) : data[e.key]}
									</span>
								</td>
							})}
							</tr>
						})}
					</tbody>
				</table>
				: null }
			</div>
		</div>
	)
}

const StatsTable = ({ destructuredIndex, destructuredSelectedComponent, hideIndex, toggleIndex, deselectComponent }) => (
	<div className="StatsTable" id={STATS_TABLE}>
		<Table
			tableClass={'index'}
			data={destructuredIndex}
			componentIsSelected={!!destructuredSelectedComponent}
			imgName={hideIndex ? hideImg : showImg }
			imgFunction={toggleIndex}
			 />
		{destructuredSelectedComponent
			? <Table
					tableClass={'component'}
					data={destructuredSelectedComponent}
					componentIsSelected={!!destructuredSelectedComponent}
					imgName={delImg}
					imgFunction={deselectComponent} />
			: null}
	</div>
)

export default StatsTable;