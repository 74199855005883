import React from "react";
import { connect } from "react-redux";
import Classnames from "classnames";
import { timeout } from "d3-timer";
import { setIntroStatus, setScrollPermissions } from "../../actions.js";
import {
  twoDecimalFormat,
  pctWithSignFormat,
  DOWN,
  MOVINGDOWN,
} from "../../constants";

import { ReactComponent as Logo } from "../../assets/primeindex-logo.svg";

import { truncate } from "../../helpers";
import styleVars from "../../styling/global_variables.scss";
import "./style.scss";

const calcLogoWidthRange = isMobile => (isMobile ? [70, 90] : [160, 180]);

const Header = ({
  data,
  scrollFunc,
  scrolledDownOrMoving,
  setScrollPermissions,
  isMobile,
}) => {
  const backgroundColor = data.change_p > 0 ? styleVars.green : styleVars.red;
  const imgWidthRange = calcLogoWidthRange(isMobile);
  return (
    <div
      className="Header"
      onClick={() => {
        setScrollPermissions(true);
        scrollFunc();
      }}
    >
      <div
        className={Classnames("fundDetails", {
          overlayVisible: scrolledDownOrMoving,
        })}
      >
        <div className="name"> {data.name} </div>
        <div>
          <span
            className={Classnames("fund", {
              longTicker: data.name.length > 5,
              overlayVisible: scrolledDownOrMoving,
            })}
          >
            {isMobile && scrolledDownOrMoving
              ? truncate(data.name, 20)
              : data.ticker}
          </span>
          <span
            className={Classnames("close", {
              longTicker: data.name.length > 5,
              overlayVisible: scrolledDownOrMoving,
            })}
          >
            {twoDecimalFormat(data.close)}
          </span>
        </div>
        <div
          className="change"
          style={{
            backgroundColor: scrolledDownOrMoving
              ? backgroundColor
              : "transparent",
          }}
        >
          {`${twoDecimalFormat(data.change)} (${pctWithSignFormat(
            data.change_p
          )})`}
        </div>
      </div>
      <div className="logo">
        <a
          href="https://www.primeindexes.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo
            className={Classnames("logo", {
              overlayVisible: scrolledDownOrMoving,
            })}
            style={{
              width:
                scrolledDownOrMoving || isMobile
                  ? `${imgWidthRange[0]}px`
                  : `${imgWidthRange[1]}px`,
            }}
          />
        </a>
      </div>
    </div>
  );
};

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.finishIntro = this.finishIntro.bind(this);
  }

  componentDidMount() {
    timeout(() => this.finishIntro(), +styleVars.introDuration.slice(0, -2));
  }

  componentDidUpdate() {
    // if the user scrolls quickly and bypasses the animation, this should also set the intro to false so the text goes away
    if (this.props.scrolledDownOrMoving && !this.props.introDone) {
      this.props.setIntroStatus(true);
    }
  }

  finishIntro() {
    this.props.setIntroStatus(true);
  }

  render() {
    return (
      <div className="Landing" onClick={this.props.scrollFunc}>
        <Header
          data={this.props.index}
          scrollFunc={this.props.scrollFunc}
          scrolledDownOrMoving={this.props.scrolledDownOrMoving}
          setScrollPermissions={this.props.setScrollPermissions}
          isMobile={this.props.isMobile}
        />
        <h1
          className={Classnames("introTitle", {
            introDone: this.props.introDone,
          })}
        >
          {" "}
          {this.props.index.name}{" "}
        </h1>
        <h2
          className={Classnames("introText", {
            introDone: this.props.introDone || this.props.isMobile,
            overlayVisible: this.props.scrolledDownOrMoving,
            "small-text":
              this.props.index.introText[0].length > 200 ||
              this.props.index.introText[1].length > 200,
          })}
        >
          {this.props.index.introText[0]}
          <br />
          <br />
          {this.props.index.introText[1]}
        </h2>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  index: (({ name, ticker, introText, close, change, change_p }) => ({
    name,
    ticker,
    introText,
    close,
    change,
    change_p,
  }))(state.data.index[0]),
  introDone: state.interaction.introDone,
  scrolledDownOrMoving:
    state.interaction.scrollSection === DOWN ||
    state.interaction.scrollSection === MOVINGDOWN,
  isMobile: state.interaction.isMobile,
});

const mapDispatchToProps = dispatch => {
  return {
    setIntroStatus: status => dispatch(setIntroStatus(status)),
    setScrollPermissions: scrollAllowed =>
      dispatch(setScrollPermissions(scrollAllowed)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing);
