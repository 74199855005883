import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

// formatters
export const volLongFormat = format(",.0f"); // ex: '1,095,540'
export const pctFormat = format(".1%"); //ex: '-20.0%'
export const pctWithSignFormat = format("+.2%"); //ex: '+20.01%'
export const pctRoundFormat = format(".0%"); //ex: '-20.0%'
export const dollarFormat = format("($.2f"); //ex: '$3.81'
export const roundNumFormat = format(".1"); //ex: '3'
export const twoDecimalFormat = num => format(".2f")(num).replace(/G/, "B"); //ex: '3.81'
export const mktCapFormat = num => format(".2s")(num).replace(/G/, "B"); //ex: '6M'
export const roundToKFormat = format(".1s"); //ex: '44k'
export const volShortFormat = format("~s"); //ex: '6M'
export const dateLongFormat = timeFormat("%B %d, %Y"); // ex: 'October 02, 2018'
export const dateShortFormat = timeFormat("%-m/%-d/%Y"); // ex: '10/02/2018'
export const dateAbbrevDay = timeFormat("%b %d"); // ex: 'Feb 01'
export const dateAbbrevYear = timeFormat("%b %y"); // ex: 'Feb 19'
export const dateTimeFormat = timeFormat("%a %I:%M %p"); // ex: 'Tues 4:30 PM'
export const timestampFormat = timeFormat("%x %I:%M"); // ex: 2/14/2019 04:00
export const getTimezone = date =>
  date.toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]; // ex: EDT

// constants
export const VIEW_OPTIONS = ["bar", "heatmap", "treemap"];
export const COLOR_OPTIONS = ["change_p", "volume"];
export const SIZE_OPTIONS = ["weight", "volume"];
export const TIME_OPTIONS = ["1D", "5D", "1M", "3M", "1Y", "YTD", "MAX"];
export const HOLDING_TABLE = "holdingTable";
export const STATS_TABLE = "statsTable";
export const TICKER = "ticker";
export const NAME = "name";
export const WEIGHT = "weight";
export const HISTORY = "history";
export const DESC = "description";
export const CLOSE = "close";
export const OPEN = "open";
export const VOL = "volume";
export const MKTCAP = "mktcap";
export const HIGH = "high52";
export const LOW = "low52";
export const CHANGE = "change";
export const CHANGE_P = "change_p";
export const CURRENCY = "currency";
export const THREEINONE = "threeInOne";
export const STATS = "stats";
export const BAR = "bar";
export const HEATMAP = "heatmap";
export const ISINDEX = "isIndex";
export const ISCONSTANT = "isConstant";
export const ISUS = "isUs";
export const DEFAULT = "default";
export const METRIC = "metric";
export const METRIC_NAME = "metric-name";
export const DOWN = "down";
export const UP = "up";
export const MOVINGDOWN = "moving";
export const UNSET = "unset";
export const HIDDEN = "hidden";
export const IMG_URL = "imgUrl";

// time values
export const MS_1_DAY = 864e5;
export const TICK_W = 5;
export const HEATMAP_MIN_W = 200;
export const HEATMAP_MAX_W = 300;
export const MAX_COMP_NUM = 20; // breakpoint for changing min/max widths

// mappings
export const PROPERTIES_MAPPING = {
  [HOLDING_TABLE]: [WEIGHT, HISTORY, DESC, IMG_URL],
  [TICKER]: [HISTORY, ISINDEX, ISCONSTANT, ISUS],
  [STATS]: [CHANGE, CHANGE_P, CLOSE, OPEN, VOL, MKTCAP, HIGH, LOW],
  [THREEINONE]: [WEIGHT, CLOSE, VOL, CHANGE_P, HISTORY],
};

export const DISPLAY_NAME_LOOKUP = {
  change_p: "% Change",
  volume: "Volume",
  bar: "Barchart",
};
